import HTTP from "@/services/HttpService";

var ForgotPassword = {

    sendInstruction: async function(forgotPasswordInput) {
        var result = false;

        await HTTP.http().post('/forgotPassword', forgotPasswordInput)
            .then(function(response) {
                // if email was not found - server returns 204 no content
                if (response.status === 200) {
                    result = true;
                }
            })
            .catch(function() {
                console.log('Email does not exists: ' + forgotPasswordInput.email);
            });

        return result;
    },

    resetPassword: async function(resetPasswordInput) {
        var result = false;

        await HTTP.http().post('/resetPassword', resetPasswordInput)
            .then(function(response) {
                // if email was not found - server returns 204 no content
                if (response.status === 200) {
                    result = true;
                }
            })
            .catch(function() {
                console.log('Failed to reset password ');
            });

        return result;
    }
}

export default ForgotPassword;
