<template>
  <div>
    <div v-if="showAlert" class="alert alert-danger alert-dismissible fade show" role="alert">
      <strong>Login Failed!</strong> Please check your login and password and try again.
      <button type="button" class="close" aria-label="Close" @click="closeAlert">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <h1>Login to RateRabbit Administration</h1>
    <form v-if="!forgotPassword" class="custom-login-form" v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          v-model="userName"
          type="text"
          class="form-control"
          id="userName"
          placeholder="Username"
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          v-model="password"
          type="password"
          class="form-control"
          id="password"
          placeholder="Password"
        />
      </div>
      <b-row class="text-center m-2" align-v="center">
        <b-col class="pb-1">
          <button type="button" class="btn text-secondary" v-on:click="forgotPassword = !forgotPassword">Forgot password?</button>
        </b-col>
        <b-col class="pb-1 text-right">
          <button type="submit" class="btn btn-secondary">Login</button>
        </b-col>
      </b-row>
    </form>

    <div v-else-if="showInstructionSendSuccessAlert" class="alert alert-success alert-dismissible fade show" role="alert">
      <strong>Password reset instructions have been successfully sent to your email address. Please follow these instructions.</strong>
      <button type="button" class="close" aria-label="Close" @click="closeSendInstructionSuccessAlert">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div v-else-if="showInstructionSendFailAlert" class="alert alert-danger alert-dismissible fade show" role="alert">
      <strong>This email address has been are not registered. Please try a different address.</strong>
      <button type="button" class="close" aria-label="Close" @click="closeSendInstructionsFailAlert">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form v-else class="custom-login-form" v-on:submit.prevent="onForgotPassword">
      <div class="form-group">
        <p v-if="errors.email && !email" class="error">
          Email is required
        </p>
        <p v-if="errors.email && email && !validEmail(email)" class="error">
          Email invalid
        </p>
        <label for="email">Email</label>
        <input
            v-model="email"
            type="text"
            class="form-control"
            id="email"
            placeholder="Email Address"
            @blur="validateEmail(email)"
        />
      </div>
      <b-row class="text-center m-2" align-v="center">
        <b-col class="pb-1">
          <button type="button" class="btn text-secondary" v-on:click="forgotPassword = !forgotPassword">Return to login</button>
        </b-col>
        <b-col class="pb-1 text-right">
          <button type="submit" :disabled="sendInstructionButtonClicked" class="btn btn-secondary">Submit</button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import Auth from '@/services/AuthService'
import User from '@/services/UserService'
import ForgotPassword from "@/services/ForgotPasswordService";
import Store from '@/store';

export default {
  name: 'login',
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  data: function () {
    return {
      userName: '',
      password: '',
      grantType: 'PASSWORD',
      showAlert: false,

      forgotPassword: false,
      email: '',
      sendInstructionButtonClicked: false,
      showInstructionSendSuccessAlert: false,
      showInstructionSendFailAlert: false,

      errors: {
        email: false,
      },
    };
  },
  methods: {
    closeAlert: function() {
      this.showAlert = false;
    },
    closeSendInstructionSuccessAlert: function () {
      this.showInstructionSendFailAlert = false;
      this.showInstructionSendSuccessAlert = false;
      this.forgotPassword = false;
    },
    closeSendInstructionsFailAlert: function () {
      this.showInstructionSendFailAlert = false;
      this.showInstructionSendSuccessAlert = false;
    },
    onSubmit: async function () {
      const user = {
        userName: this.userName,
        password: this.password,
        grantType: this.grantType
      }
      await Auth.login(user);

      if (Store.state.isLoggedIn) {
        await User.getMyUserInfo();

        this.$router.push({
          name: 'Applications'
        });
      } else {
        this.showAlert = true;
      }
    },
    onForgotPassword: async function () {

      this.validateEmail(this.email)

      const forgotPasswordInput = {
        email : this.email
      }

      if (!this.errors.email) {
        this.sendInstructionButtonClicked = true;

        let result = await ForgotPassword.sendInstruction(forgotPasswordInput);

        if (result) {
          this.showInstructionSendSuccessAlert = true;
        } else {
          this.showInstructionSendFailAlert = true;
        }
      }
    },
    validateEmail (value) {
      if (!this.validEmail(value)) {
        this.errors.email = true
      }
      if (value && this.validEmail(value)) {
        this.errors.email = false
      }
    },
    validEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  }
}
</script>
